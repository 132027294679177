<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{this.title}}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">

                        <!--#region CreateDateFrom  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.CreateDateFrom.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-menu 
                                    v-model="createDateFromMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                >
                                    
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="val.CreateDateFrom.value | formattedDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on" 
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            clearable
                                            @click:clear="val.CreateDateFrom.value = null"
                                            class="datepick-input"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker 
                                        v-model="val.CreateDateFrom.value"
                                        @input="createDateFromMenu = false"
                                        color="teal"
                                        :first-day-of-week="1"
                                    >
                                    </v-date-picker>

                                </v-menu>

                            </v-col>
                        </v-row>
                        <!--#endregion -->

                        <!--#region CreateDateFrom  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.CreateDateTo.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-menu 
                                    v-model="createDateToMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                >

                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="val.CreateDateTo.value | formattedDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on" 
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            clearable
                                            @click:clear="val.CreateDateTo.value = null"
                                            class="datepick-input"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker 
                                        v-model="val.CreateDateTo.value"
                                        @input="createDateToMenu = false"
                                        color="teal"
                                        :first-day-of-week="1"
                                    >
                                    </v-date-picker>
                                </v-menu>

                            </v-col>
                        </v-row>
                        <!--#endregion -->
                
                        <!--#region RegDateFrom  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.RegDateFrom.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-menu 
                                    v-model="regDateFromMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                >

                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="val.RegDateFrom.value | formattedDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on" 
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            clearable
                                            @click:clear="val.RegDateFrom.value = null"
                                            class="datepick-input"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker 
                                        v-model="val.RegDateFrom.value"
                                        @input="regDateFromMenu = false"
                                        color="teal"
                                        :first-day-of-week="1"
                                    >
                                    </v-date-picker>
                                </v-menu>

                            </v-col>
                        </v-row>
                        <!--#endregion -->

                        <!--#region RegDateTo  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.RegDateTo.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-menu 
                                    v-model="regDateToMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="val.RegDateTo.value | formattedDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on" 
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            clearable
                                            @click:clear="val.RegDateTo.value = null"
                                            class="datepick-input"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker 
                                        v-model="val.RegDateTo.value"
                                        @input="regDateToMenu = false"
                                        color="teal"
                                        :first-day-of-week="1"
                                    >
                                    </v-date-picker>
                                </v-menu>

                            </v-col>
                        </v-row>
                        <!--#endregion -->
                        
                        <!--#region DeclarantType  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.DeclarantType.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="GetIQalaDeclarantCategoryTypes"
                                    v-model="val.DeclarantType.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    item-value="id"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    @click:clear="val.DeclarantType.value = null"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                >
                                    <template v-slot:selection="data">
                                        {{ $t(data.item.Value) }}
                                    </template>

                                    <template v-slot:item="data">
                                        {{ $t(data.item.Value) }}
                                    </template>
                                </v-select>

                            </v-col>
                        </v-row>
                        <!--#endregion -->

                        <!--#region ExecDateFrom  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.ExecDateFrom.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-menu 
                                    v-model="execDateFromMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                >

                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="val.ExecDateFrom.value | formattedDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on" 
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            clearable
                                            @click:clear="val.ExecDateFrom.value = null"
                                            class="datepick-input"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker 
                                        v-model="val.ExecDateFrom.value"
                                        @input="execDateFromMenu = false"
                                        color="teal"
                                        :first-day-of-week="1"
                                    >
                                    </v-date-picker>
                                </v-menu>

                            </v-col>
                        </v-row>
                        <!--#endregion -->

                        <!--#region ExecDateTo  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.ExecDateTo.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-menu 
                                    v-model="execDateToMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290"
                                >

                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            :value="val.ExecDateTo.value | formattedDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on" 
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            clearable
                                            @click:clear="val.ExecDateTo.value = null"
                                            class="datepick-input"
                                        >
                                        </v-text-field>
                                    </template>

                                    <v-date-picker 
                                        v-model="val.ExecDateTo.value"
                                        @input="execDateToMenu = false"
                                        color="teal"
                                        :first-day-of-week="1"
                                    >
                                    </v-date-picker>
                                </v-menu>

                            </v-col>
                        </v-row>
                        <!--#endregion -->

                        <!--#region ExecuterWorkplaceId  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.ExecuterWorkplaceId.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="workplacesItems"
                                    v-model="val.ExecuterWorkplaceId.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    @click:clear="val.ExecuterWorkplaceId.value = null"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                ></v-select>

                            </v-col>
                        </v-row>
                        <!--#endregion -->

                        <!--#region RegistratorWorkplaceId  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.RegistratorWorkplaceId.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="workplacesItems"
                                    v-model="val.RegistratorWorkplaceId.value"
                                    :item-text="item => $refLocale(item, $i18n.locale)"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable                                    
                                    @click:clear="val.RegistratorWorkplaceId.value = null"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                ></v-select>

                            </v-col>
                        </v-row>
                        <!--#endregion -->

                        <!--#region CameFrom  -->
                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="4">
                                <label class="f-label">{{$t(val.CameFrom.label)}}</label>
                            </v-col>
                            <v-col cols="12" sm="12" md="8">

                                <v-select
                                    return-object
                                    :items="comeFromItems"
                                    v-model="val.CameFrom.value"
                                    :item-text="item => $t(item.Value)"
                                    item-value="id"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    clearable
                                    @click:clear="val.CameFrom.value = null"
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                ></v-select>

                            </v-col>
                        </v-row>
                        <!--#endregion -->

                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="cyan"
                        text
                        depressed
                        @click="save" 
                        v-if="isFormValid"
                    >
                        {{$t("Применить_фильтр")}}                    
                    </v-btn>
                    <v-btn 
                        color="blue-grey" 
                        text 
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import sys from '@/services/system';

export default {
    name: "CSCExtendedFilter",
    props:
    {
        value:
        {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            title: "Фильтр",
            isFormValid: true,
            visible: false,
            resolve: null,
            reject: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },

            val: JSON.parse(JSON.stringify(this.value)),
            
            createDateFromMenu: false,
            createDateToMenu: false,
            regDateFromMenu: false,
            regDateToMenu: false,            
            execDateFromMenu: false,
            execDateToMenu: false,
            workplacesItems: [],
            comeFromItems: [{ id: 1, Value: "Портал" }, { id: 2, Value: "Фронт-офис" }, { id: 3, Value: "Проактив" }],
        }
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    computed:{
        ...mapGetters('references', ['GetIQalaDeclarantCategoryTypes']),
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        ...mapActions('references', ['getWorkplaces']),
        async initialize() {            
            this.workplacesItems = (await this.getWorkplaces()).InnerExecuters.Executers.map(i =>                 
                ({
                    id: i[0],
                    Value: i[1],
                    employeeId: i[2],
                    jobTitle: i[3]
                })
            );
        },
        async open(options = {})
        {
            this.val = JSON.parse(JSON.stringify(this.value)),
            this.visible = true;
            this.options = Object.assign(this.options, options);
            this.setOverlayVisible({ visible: true });
            this.initialize();
            this.setOverlayVisible({ visible: false });

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        cancel() {
            this.visible = false;
            this.reject({
                isCancelled: true,
                message: "Действие_отменено"
            });                
        },
        save() {
            this.visible = false;
            this.$emit('input', this.val);                                
            //this.$notify.success("Фильтр_применен");
            this.resolve();
        },
        handleDate(source) {
            var formatedDate = sys.dateFormat(source, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    }
}

</script>